import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import Main from "../pages/main"; 
import Mall from "../pages/octopus"; 
import DealTaskInfo from "../pages/octopus";
import DealSourceInfo from "../pages/dealsource";
import DealCatInfo from "../pages/category";

const routers = [
    {
        path: "/",
        Component: Main,
        children: [
            {
                path: "/",
                element: <Navigate to="octopus" replace/>,
              }, 
          {
            path: "octopus",
            Component: DealTaskInfo,
          } , 
          {
            path: "dealsource",
            Component: DealSourceInfo,
          }, 
          {
            path: "dealCatInfo",
            Component: DealCatInfo,
          } 
        ]
      }
      
];


// Or use plain objects
export default createBrowserRouter(routers);