export default  [
    {
        path: '/octopus',
        name: 'home',
        label: '爬虫任务监控',
        icon: 'HomeOutlined',
        url: '/octopus/index'
    },
    {
        path: '/dealsource',
        name: 'dealsource',
        label: 'Deal Souce',
        icon: 'HomeOutlined',
        url: '/dealsource/index'
    },
    {
        path: '/dealCatInfo',
        name: 'dealCatInfo',
        label: 'Deal Cat Stats',
        icon: 'HomeOutlined',
        url: '/dealcat/index'
    }
]