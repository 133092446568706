import { Navigate } from "react-router-dom";


const RouterAuth = ({children}) => {
    const token = "xxx";//localStorage.getItem('token');
    if (!token) {
        return <Navigate to='/login' replace></Navigate>
    }

    return (
        children
    )

}

export default RouterAuth;